import { HomeCounterMapData } from "@/DummyData";
// import { RecommendedH2, RecommendedInnerHeadingMain } from "@/StyledComponents/LandingStyled";
// import {
//     SoftwareBottomText,
//     SoftwareCardItems,
//     SoftwareCardsMain,
//     SoftwareTodayMain,
//     SoftwareTopHeading
// } from "@/StyledComponents/ShopifyStyled";
import { Icon } from "@iconify/react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Container } from "reactstrap";
import CounterBigCurveSvg from "../svg/CounterBigCurveSvg";
import HalfShortArrow from "../svg/Home/HalfShortArrow";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  RecommendedH2,
  RecommendedInnerHeadingMain,
  SoftwareBottomText,
  SoftwareCardItems,
  SoftwareCardsMain,
  SoftwareTodayMain,
  SoftwareTopHeading,
} from "@/StyledComponents/CounterSectionStyle";

gsap.registerPlugin(ScrollTrigger);

export default function CounterSection() {
  const cardsRef = useRef([]);

  useEffect(() => {
    if (cardsRef.current.length > 0) {
      gsap.set(cardsRef.current, { x: -100, opacity: 0 });

      gsap.to(cardsRef.current, {
        x: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.3,
        ease: "power2.out",
        scrollTrigger: {
          trigger: ".counter-row",
          start: "top 80%", // Start when the section is 80% into view
          toggleActions: "play none none none",
        },
      });
    }
  }, []);

  return (
    <Container className="p-t-section">
      <SoftwareTodayMain>
        <RecommendedInnerHeadingMain className="counter">
          <RecommendedH2 className="max-w-[300px] md:max-w-[500px] lg:max-w-[500px] xl:max-w-[800px] countertitle">
            Your&nbsp;
            <span className="relative">
              {" "}
              Software
              <span className="absolute md:w-[30px] lg:w-[35px] fullsizesvgHauto top-[-5px] right-[-20px] lg:right-[-25px]">
                <HalfShortArrow />
              </span>
            </span>
            <div className="flex items-start">is in Trusted Hands</div>
          </RecommendedH2>
          <CounterBigCurveSvg />
        </RecommendedInnerHeadingMain>
        <SoftwareCardsMain className="counter-row">
          {HomeCounterMapData.map((d, index) => (
            <CounterCard
              key={d.id}
              data={d}
              innerRef={(el) => (cardsRef.current[index] = el)}
            />
          ))}
        </SoftwareCardsMain>
      </SoftwareTodayMain>
    </Container>
  );
}

function CounterCard({ data, innerRef }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Triggers when 50% of the card is visible
  });

  const extractNumber = (str) => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  const startValue = 0;
  const endValue = extractNumber(data.number);

  return (
    <SoftwareCardItems
      ref={(el) => {
        ref(el);
        innerRef(el);
      }}
      className="counter-col perfect-match-card"
    >
      <div>
        <SoftwareTopHeading>
          {inView ? (
            <CountUp start={startValue} end={endValue} duration={2.5} />
          ) : (
            startValue
          )}
          {data.number.replace(/^\d+/, "")}
        </SoftwareTopHeading>
        <div>{data.subtext}</div>
      </div>
      <SoftwareBottomText className="!text-inherit">
        {data.box_no}{" "}
        {data.id === "4" && (
          <Icon icon="tabler:arrow-right" className="!text-[#000]" />
        )}
      </SoftwareBottomText>
    </SoftwareCardItems>
  );
}
