import styled from "styled-components";

export const RecommendedH2 = styled.div`
  font-weight: 600;
  font-size: 84px;
  line-height: 92px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  &.formtitle,
  &.countertitle {
    font-size: 60px;
    line-height: 70px;
  }

  @media (max-width: 1024px) {
    &.formtitle,
    &.countertitle {
      font-size: 46px;
      line-height: 56px;
    }
    font-size: 50px;
    line-height: 60px;
    align-items: end;
    row-gap: 5px;
  }
  @media screen and (max-width: 991px) {
    &.formtitle,
    &.countertitle {
      font-size: 34px;
      line-height: 46px;
    }
    &.clutch-review-h2 {
      margin-left: 20px;
    }
  }
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 40px;
    align-items: end;
    row-gap: 5px;
    &.formtitle,
    &.countertitle {
      font-size: 26px;
      line-height: 36px;
      text-align: center;
    }
    &.clutch-review-h2 {
      margin-left: 10px;
    }
  }
`;

export const RecommendedInnerHeadingMain = styled.div`
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  &.counter {
    margin-bottom: 50px;
  }
  @media (min-width: 1440px) {
    &.counter {
      margin-bottom: 100px;
    }
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 600px) {
    justify-content: center;
    &.counter {
      flex-direction: row;
    }
    flex-direction: column;
    /* align-items: start; */
    gap: 30px;
    margin-bottom: 10px;
  }
`;

export const SoftwareBottomText = styled.div`
  font-size: 26px;
  font-weight: 400;
  color: #000000;
  &.case-subtxt {
    font-size: 19px;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 52px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const SoftwareCardItems = styled.div`
  width: 25%;
  height: 296px;
  border: 0.5px solid var(--border-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  &.case-count-col {
    width: 100%;
    max-height: 282px;
    padding: 40px;
  }
  &.counter-col {
    width: 100%;
    max-height: 422px;
    border: 0px solid var(--border-color);
  }
  &.active {
    background: #ff76bb;
    border: none;
  }
  &:hover {
    border-color: #000;
  }
  @media screen and (min-width: 1440px) {
    &.counter-col {
      padding: 55px;
      min-height: 422px;
    }
    &.case-count-col {
      padding: 40px;
      max-height: 282px;
    }
  }
  @media (max-width: 991px) {
    width: 45%;
    .counter-col {
      min-height: 290px;
      padding: 24px;
    }
    &.case-count-col {
      padding: 24px;
      max-height: 282px;
    }
  }
  @media screen and (max-width: 767px) {
    .counter-col {
      min-height: 246px;
      margin-bottom: 16px;
    }
    &.case-count-col {
      margin-bottom: 16px;
      max-height: 282px;
    }
  }
  @media (max-width: 479px) {
    width: 100%;
    height: 215px;
    .counter-col {
      min-height: 214px;
    }
    &.case-count-col {
      max-height: 282px;
    }
  }
`;

export const SoftwareCardsMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  &.counter-row {
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  @media screen and (min-width: 1440px) {
    &.counter-row {
      grid-column-gap: 24px;
      grid-row-gap: 0px;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
    &.counter-row {
      grid-column-gap: 24px;
      grid-row-gap: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    &.counter-row {
      grid-template-columns: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  }
`;

export const SoftwareTodayMain = styled.div``;

export const SoftwareTopHeading = styled.div`
  font-size: 58px;
  font-weight: 500;
  &.case-heading {
    font-size: 42px;
    line-height: 42px;
    font-weight: 500;
    color: #000000;
  }

  &.active {
    font-size: 34px;
    font-weight: 500;
    line-height: 44px;
    color: #000000;
  }
  @media (max-width: 1024px) {
    font-size: 46px;
  }
  @media (max-width: 991px) {
    font-size: 34px;
    &.active {
      font-size: 34px;
      line-height: 35px;
    }
  }
  @media (max-width: 479px) {
    font-size: 28px;
    &.active {
      font-size: 28px;
      line-height: 35px;
    }
  }
`;